import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import Hero from '../components/hero'

import * as styles from '../templates/blog-post.module.css'

class TermsTemplate extends React.Component {
  render() {
    const [post] = get(this.props, 'data.allContentfulLegalInformation.nodes')

    return (
      <Layout location={this.props.location}>
        <Hero
          title={post.title}
        />
        <div className={styles.container}>
          <div className={styles.article}>
            <div
              className={styles.body}
              dangerouslySetInnerHTML={{
                __html: post.content?.childMarkdownRemark?.html,
              }}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export default TermsTemplate

export const pageQuery = graphql`
query TermsQuery {
    allContentfulLegalInformation(
      filter: { contentful_id: { eq: "1AtK9VvdJjBNSspyV2swO0" } }
    ) {
      nodes {
        title
        content {
            childMarkdownRemark {
              html
            }
        }
        }
      }
}
`